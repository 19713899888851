.App {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: max-content;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}

.unityContainer {
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  background-color: rgb(145, 145, 145);
}

.loading-overlay {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(51, 48, 48);
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../public/unity/TemplateData/splash-bg.png);
}

.progress-bar {
  max-width: 1030px;
  height: 47.11px;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  bottom: 160px;
}

.progress-bar-fill {
  max-width: 1010px;
  transition: width 0.5s ease;
  position:absolute;
  top: 9px;
  left: 10px;
  height: 26.91px;
  overflow: hidden;
  border-radius: 40px;
}
.progress-bar img{width: 100%; height: 100%;}
.progress-bar-fill img{height: 100%;}
.loading-overlay, 
.progress-bar, 
.progress-bar-fill{background-size: cover}
.unity-canvas {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
@media (max-width: 1280px) {
  .progress-bar{max-width: 900px;}
  .progress-bar-fill{max-width: 881px;}
}
@media (max-width: 1280px) {
  .progress-bar{max-width: 750px;}
  .progress-bar-fill{max-width: 735px;}
}
@media (max-width: 768px) {
  .progress-bar{max-width: 600px;}
  .progress-bar-fill{
    max-width: 587px;
    left: 7px;
  }
}
@media (max-width: 768px) {
  .progress-bar{max-width: 530px;}
  .progress-bar-fill{
    max-width: 518px;
    left: 6px;
  }
}


.accelerated {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s ;
}